"use client"
import { SUPPORT_EMAIL } from "@/constants/config"
import { DISCORD_URL, LINKEDIN_URL, ROUTES, TWITTER_URL } from "@/constants/routes"
import { Box, Container, Grid, SxProps, Theme, Typography } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { PlainLink } from "../links/PlainLink"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import Logo from "./Logo"

export default function Footer() {
    return (
        <Container component="footer">
            <SlideUpAnimation>
                <Grid container pb={4} py={{ xs: 2, md: 8 }} rowGap={4}>
                    <Grid item xs={6} lg={3} sx={styles.footerSection}>
                        <Logo pb={2} size="sm" />
                        <PlainLink
                            href={`mailto:${SUPPORT_EMAIL}`}
                            aria-label="Email address link"
                            sx={styles.link}
                        >
                            {SUPPORT_EMAIL}
                        </PlainLink>
                        <PlainLink
                            href={ROUTES.PRIVACY_POLICY}
                            aria-label="Privacy Policy link button"
                            sx={styles.link}
                        >
                            Privacy Policy
                        </PlainLink>
                        <Typography variant="caption">All rights reserved</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3} sx={styles.footerSection}>
                        <Typography
                            color="text.secondary"
                            pb={1}
                            variant="h6"
                            component="p"
                            fontWeight={600}
                        >
                            Product
                        </Typography>

                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.HOME}
                            aria-label="Home link button"
                        >
                            Home
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.PRICING}
                            aria-label="Pricing link button"
                        >
                            Pricing
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.BLOG}
                            aria-label="Blog link button"
                        >
                            Blog
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.SUMMARIES}
                            aria-label="Summaries link button"
                        >
                            Summaries
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.DOCS}
                            aria-label="Docs link button"
                            target="_blank"
                        >
                            Docs
                        </PlainLink>
                    </Grid>
                    <Grid item xs={6} lg={3} sx={styles.footerSection}>
                        <Typography
                            color="text.secondary"
                            pb={1}
                            variant="h6"
                            component="p"
                            fontWeight={600}
                        >
                            Feedback
                        </Typography>
                        <PlainLink sx={styles.link} href={ROUTES.FAQ} aria-label="Faq link button">
                            FAQ
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.ROADMAP}
                            aria-label="Roadmap link button"
                            target="_blank"
                        >
                            Roadmap
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.FEATURE_REQUEST}
                            aria-label="Feature request link button"
                            target="_blank"
                        >
                            Feature Request
                        </PlainLink>
                        <PlainLink
                            sx={styles.link}
                            href={ROUTES.CHANGE_LOG}
                            aria-label="Change log link button"
                            target="_blank"
                        >
                            Change Log
                        </PlainLink>
                    </Grid>
                    <Grid item xs={6} lg={3} sx={styles.footerSection}>
                        <Typography
                            color="text.secondary"
                            pb={2}
                            variant="h6"
                            component="p"
                            fontWeight={600}
                        >
                            External Links
                        </Typography>

                        <Box display="flex" gap={2}>
                            <Link href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
                                <Image
                                    src="/svgs/discord.svg"
                                    alt="Discord Logo"
                                    width={40}
                                    height={38}
                                />
                            </Link>
                            <Link target="_blank" rel="noopener noreferrer" href={LINKEDIN_URL}>
                                <Image
                                    src="/svgs/linkedin.svg"
                                    alt="Linkedin Logo"
                                    width={40}
                                    height={38}
                                />
                            </Link>
                            <Link rel="noopener noreferrer" target="_blank" href={TWITTER_URL}>
                                <Image
                                    src="/svgs/twitter.svg"
                                    alt="Twitter Logo"
                                    width={40}
                                    height={38}
                                />
                            </Link>
                        </Box>
                        <Link
                            href="https://www.producthunt.com/products/recall-6/reviews?utm_source=badge-product_rating&amp;utm_medium=badge&amp;utm_souce=badge-recall-6"
                            target="_blank"
                        >
                            <Box
                                mt={2}
                                component="img"
                                src="/svgs/product-hunt-reviews.svg"
                                alt="Recall - Product Hunt reviews"
                                width={160}
                                height={70}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    footerSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    link: { py: 1, pr: 1, minWidth: 48 },
}
